import React, { CSSProperties } from 'react'
import { Editor, Element as SlateElement, Transforms } from 'slate'
import { RenderElementProps, RenderLeafProps, useSlate } from 'slate-react'
import { Button, GlobalStyles, Text } from '@andromeda'
import { CustomEditor, CustomElement, CustomText, ParagraphStyle, TextFormatting } from './custom-types'
import { IconButton } from '@mui/material'
import { Mention } from '@components'
import { Types } from '@pickstar/orbit'
import { RichEditorLink } from 'src/components/rich-editor-plugin/link'

const LIST_TYPES = ['ol_list', 'ul_list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export type ElementProps = RenderElementProps & {
  attributes: React.Attributes
  children: JSX.Element
  element: CustomElement
  users?: Types.User.iUser[]
  updateLink?: (element: CustomElement) => void
}

export interface LeafProps extends RenderLeafProps {
  attributes: { 'data-slate-leaf': true }
  children: JSX.Element
  leaf: CustomText
}

export const toggleBlock = (editor: CustomEditor, format: TextFormatting) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list_item' : format,
    } as CustomElement
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] } as CustomElement
    Transforms.wrapNodes(editor, block)
  }
}

export const toggleMark = (editor: CustomEditor, format: ParagraphStyle) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isBlockActive = (editor: CustomEditor, format: TextFormatting, blockType: 'type' | 'children' | 'align' = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  )

  return !!match
}

export const isMarkActive = (editor: CustomEditor, format: ParagraphStyle) => {
  const marks: Omit<CustomText, 'text'> | null = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const Element = (props: ElementProps) => {
  const { attributes, children, element, users } = props
  const style = { textAlign: element.align, fontFamily: 'Inter' } as CSSProperties
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'ul_list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading_one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading_two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list_item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'ol_list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'mention':
      return <Mention users={users} {...props} />
    case 'link':
      return <RichEditorLink {...props} />
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

export const Leaf = ({ attributes, children, leaf }: LeafProps) => {
  const style = { fontFamily: 'Inter' } as CSSProperties
  if (leaf.bold) {
    children = <strong style={style}>{children}</strong>
  }

  if (leaf.code) {
    children = <code style={style}>{children}</code>
  }

  if (leaf.italic) {
    children = <em style={style}>{children}</em>
  }

  if (leaf.underline) {
    children = <u style={style}>{children}</u>
  }

  return (
    <span style={style} {...attributes}>
      {children}
    </span>
  )
}

export const BlockButton = ({ format, ButtonIcon }: { format: TextFormatting; ButtonIcon: JSX.Element | string }) => {
  const editor = useSlate()
  const isSelected = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
  return (
    <Button
      transparent
      rounded={false}
      style={[isSelected && { backgroundColor: GlobalStyles.SLATE_800 }]}
      onPress={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}>
      <Text style={[isSelected && { color: GlobalStyles.MONO_WHITE }]}>{ButtonIcon}</Text>
    </Button>
  )
}

export const MarkButton = ({
  format,
  ButtonIcon,
  disabled,
  ...props
}: {
  format: ParagraphStyle
  ButtonIcon: JSX.Element
  accessibilityLabel?: string
  disabled?: boolean
}) => {
  const editor = useSlate()
  const isSelected = isMarkActive(editor, format)
  return (
    <IconButton
      onClick={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      sx={{ svg: { strokeWidth: isSelected ? 3 : 2 } }}>
      <Text style={[isSelected && { color: GlobalStyles.PRIMARY_500 }]}>{ButtonIcon}</Text>
    </IconButton>
  )
}
