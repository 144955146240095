import { GlobalStyles, Icon, Loading, StackV2 } from '@andromeda'
import IconButton from '@mui/material/IconButton'
import { markdownToSlate, slateToMarkdown } from '@utils/functions/helperFunctions'
import isHotkey from 'is-hotkey'
import { KeyboardEventHandler, forwardRef, useCallback, useMemo, useState } from 'react'
import { Descendant, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, ReactEditor, Slate, withReact } from 'slate-react'
import { HotKeyCombination } from './custom-types'
import { BlockButton, Element, ElementProps, Leaf, LeafProps, MarkButton, toggleMark } from './tools'
import { useMentionSelect, extractMetionedUsers, withMentions } from 'src/components/mention'
import { Types } from '@pickstar/orbit'
import { MentionSelect } from '@components'
import { Button } from '@mui/material'
import { withLinks } from 'src/components/rich-editor-plugin/link/utils'
import { RichEditorLinkDialog, useRichEditorLink } from 'src/components/rich-editor-plugin/link'

const HOTKEYS: HotKeyCombination = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  // 'mod+u': 'underline',
  'mod+`': 'code',
}

const defaultValue: Descendant[] = [{ type: 'paragraph', children: [{ text: '' }] }]

export type RichEditorValue = {
  formatted: string
  plaintext: string
  mentions?: Types.User.iUser[]
}

interface iRichEditor {
  initialValue?: string | null
  onClickSave?: (comment: RichEditorValue, onSuccess: (callback: () => void) => void) => void
  onClickCancel?: () => void
  isSaving?: boolean
  disabled?: boolean
  placeHolder?: string
  onChange?: (e: string, plaintext?: string) => void
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>
  showFooter?: boolean
  showInlineSendButton?: boolean
  mentions?: Types.User.iUser[]
  type?: 'job' | 'deliverable'
}

const RichTextEditor = ({ showFooter = true, showInlineSendButton = true, mentions, type, ...props }: iRichEditor, _ref?: any) => {
  const renderElement = useCallback((props: ElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: LeafProps) => <Leaf {...props} />, [])
  const { initialValue = '', onClickSave, onClickCancel, isSaving } = props
  const editor = useMemo(() => withMentions(withLinks(withHistory(withReact(createEditor())))), [])
  const [content, setContent] = useState<Descendant[]>(initialValue ? (markdownToSlate(initialValue) as Descendant[]) : defaultValue)

  const mentionSelect = useMentionSelect(editor, mentions || [], type)
  const link = useRichEditorLink(editor)

  // useEffect(() => {
  //     setContent(markdownToSlate(initialValue) as Descendant[])
  //     console.log('initialValue', initialValue)
  //     console.log('initialValue', markdownToSlate(initialValue))
  // }, [initialValue])

  // const initialEditorValue: Descendant[] = [
  //     {
  //         type: 'paragraph',
  //         children: [{ text: '' }],
  //     },
  // ]

  const _handleSave = () => {
    const editorDOM = ReactEditor.toDOMNode(editor, editor)
    const comment: RichEditorValue = {
      plaintext: editorDOM.innerText,
      formatted: slateToMarkdown(editor.children as Descendant[]),
      mentions: extractMetionedUsers(editor.children as Descendant[]) || [],
    }

    onClickSave &&
      onClickSave(comment, (callback: () => void) => {
        // https://github.com/ianstormtaylor/slate/issues/713#issuecomment-921071287
        const point = { path: [0, 0], offset: 0 }
        editor.selection = { anchor: point, focus: point } // clean up selection
        editor.history = { redos: [], undos: [] } // clean up history
        editor.children = [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ]

        Promise.resolve().then(() => callback())
      })
  }

  // const _handleCancel = () => {
  //     onClickCancel && onClickCancel()
  //     _resetEditor()
  // }

  // useEffect(() => {
  //     if (isSaving !== undefined && !isSaving) _resetEditor()
  // }, [isSaving])

  // useEffect(() => {
  //     setContent(initialValue)
  // }, [initialValue])

  return (
    // <Fragment key={content}>
    <Slate
      editor={editor}
      initialValue={initialValue ? (markdownToSlate(initialValue) as Descendant[]) : defaultValue}
      onChange={(e) => {
        const editorDOM = ReactEditor.toDOMNode(editor, editor)
        props.onChange && props.onChange(slateToMarkdown(e), editorDOM.innerText)
        mentionSelect.onChange(e)
      }}>
      {link.showEditor && (
        <RichEditorLinkDialog text={link.text} link={link.link} isOpen={link.showEditor} onClose={link.closeLinkEditor} />
      )}

      <StackV2 direction="column" flex={1} width={'100%'}>
        <StackV2
          direction="column"
          sx={{
            borderWidth: 2,
            borderRadius: '6px',
            borderColor: GlobalStyles.SLATE_100,
            borderStyle: 'solid',
            paddingX: '12px',
            backgroundColor: GlobalStyles.SLATE_50,
          }}>
          <StackV2
            direction={'row'}
            height={'auto'}
            sx={{
              flexWrap: 'wrap',
            }}>
            <MarkButton
              disabled={isSaving}
              accessibilityLabel="bold"
              format="bold"
              ButtonIcon={<Icon.Bold size={GlobalStyles.ICON_SIZE} />}
            />
            <MarkButton
              disabled={isSaving}
              accessibilityLabel="italic"
              format="italic"
              ButtonIcon={<Icon.Italic size={GlobalStyles.ICON_SIZE} />}
            />
            {/* <MarkButton
              disabled={isSaving}
              accessibilityLabel="underline"
              format="underline"
              ButtonIcon={<Icon.Underline size={GlobalStyles.ICON_SIZE} />}
            /> */}
            <BlockButton format="heading_one" ButtonIcon={'H1'} />
            <BlockButton format="heading_two" ButtonIcon={'H2'} />
            <BlockButton format="ul_list" ButtonIcon={<Icon.List size={GlobalStyles.ICON_SIZE} />} />
            <BlockButton format="ol_list" ButtonIcon={<Icon.NumberList size={GlobalStyles.ICON_SIZE} />} />
            <Button type="button" variant="text" style={{ color: GlobalStyles.SLATE_600 }} onClick={link.openLinkEditor}>
              <Icon.Link2 size={GlobalStyles.ICON_SIZE} />
            </Button>
            {/* <MarkButton format="code" ButtonIcon={<Icon.Code size={GlobalStyles.ICON_SIZE} />} />
                        <BlockButton format="heading-one" ButtonIcon={'H1'} />
                        <BlockButton format="heading-two" ButtonIcon={'H2'} />
                        <BlockButton format="block-quote" ButtonIcon={<Icon.ChevronsRight size={GlobalStyles.ICON_SIZE} />} />
                        <BlockButton format="numbered-list" ButtonIcon={<Icon.List size={GlobalStyles.ICON_SIZE} />} />
                        <BlockButton format="bulleted-list" ButtonIcon={<Icon.List size={GlobalStyles.ICON_SIZE} />} />
                        <BlockButton format="left" ButtonIcon={<Icon.AlignLeft size={GlobalStyles.ICON_SIZE} />} />
                        <BlockButton format="center" ButtonIcon={<Icon.AlignCenter size={GlobalStyles.ICON_SIZE} />} />
                        <BlockButton format="right" ButtonIcon={<Icon.AlignRight size={GlobalStyles.ICON_SIZE} />} />
                        <BlockButton format="justify" ButtonIcon={<Icon.AlignJustify size={GlobalStyles.ICON_SIZE} />} /> */}
          </StackV2>
          <StackV2
            direction={'row'}
            className="relative"
            sx={{
              flex: 1,
              alignItems: 'flex-end',
              paddingBottom: '10px',
              '& > div': {
                backgroundColor: 'white',
                borderRadius: '6px',
                inlineSize: 'calc(100% - 10px)',
                '>p': {
                  wordBreak: 'break-word',
                },
              },
            }}
            spacing={1}>
            <Editable
              aria-label="conversations"
              style={{
                flex: 1,
                minHeight: 48,
                userSelect: 'none',
                padding: '0 12px',
                fontSize: '14px',
                fontWeight: '400',
                color: GlobalStyles.SLATE_700,
              }}
              contentEditable={false}
              renderElement={(props) => renderElement({ ...props, users: mentions, updateLink: link.updateLink })}
              renderLeaf={renderLeaf}
              placeholder={props.placeHolder || 'Add a comment...'}
              spellCheck
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault()
                    const mark = HOTKEYS[hotkey]
                    toggleMark(editor, mark)
                  }
                }

                props.onKeyDown && props.onKeyDown(event)
                mentionSelect.onKeyDown(event)
              }}
              readOnly={props.disabled || isSaving}
              renderPlaceholder={({ children, attributes }) => (
                <div {...attributes}>
                  <span className="inline-block" style={{ margin: `${GlobalStyles.MARGIN_SIZES.sm}px 0` }}>
                    {children}
                  </span>
                </div>
              )}
            />

            {showInlineSendButton && (
              <IconButton
                aria-label="submit conversation"
                disabled={props.disabled || isSaving}
                onClick={() => _handleSave()}
                style={{ height: 40, width: 40, borderRadius: 8, backgroundColor: GlobalStyles.PRIMARY_500 }}>
                {isSaving ? (
                  <Loading animating type={'white'} />
                ) : (
                  <Icon.Send color={GlobalStyles.MONO_WHITE} size={GlobalStyles.FONT_SIZES.SMALL} />
                )}
              </IconButton>
            )}

            {mentionSelect.target && mentionSelect.filteredSearch.length > 0 && (
              <MentionSelect
                position={mentionSelect.dropdownPosition}
                collection={mentionSelect.filteredSearch}
                selectedIndex={mentionSelect.selectedIndex}
                onSelect={mentionSelect.selectMention}
                onHover={(i) => mentionSelect.setSelectedIndex(i)}
                onClickOuside={() => mentionSelect.setTarget(undefined)}
              />
            )}
          </StackV2>
        </StackV2>
      </StackV2>
    </Slate>
    // </Fragment>
  )
}

export default forwardRef(RichTextEditor)
