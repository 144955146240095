import { GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { detachAgreementAttachment, generateAgreementFileDownloadUrl } from '@redux/reducers/agreementsReducer'
import { detachDeliverableAttachment, generateDeliverableFileDownloadUrl } from '@redux/reducers/deliverableReducer'
import { detachJobAttachment, generateJobFileDownloadUrl, selectJobs } from '@redux/reducers/jobsReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { PayloadAction } from '@reduxjs/toolkit'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { JobPermission } from '@utils/enum/permissions'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { PermissionWrapper } from '../hocs'
import { AttachmentPreview } from './modal'
import { OVERFLOW_TEXT } from '../job-card/job-card'
import { detachTalentAttachment, generateTalentFileDownloadUrl } from '@redux/reducers/talentProfileReducer'
import { useQueryClient } from '@tanstack/react-query'

export const FILE_TYPES = {
  doc: ['doc', 'docx'],
  pdf: ['pdf'],
  video: ['mp4', 'mov', 'avi', 'wmv', 'flv', 'mkv'],
  sheet: ['xls', 'xlsx', 'csv'],
  image: ['jpg', 'jpeg', 'png', 'gif', 'svg'],
}

const style = { color: GlobalStyles.SLATE_300, size: 20 }

const getFileIcon = (type: string) => {
  const fileType = Object.keys(FILE_TYPES).find((key) => (FILE_TYPES as { [key: string]: string[] })[key].includes(type.toLowerCase()))

  switch (fileType) {
    case 'image':
      return <Icon.Image {...style} />
    case 'doc':
      return <Icon.File {...style} />
    case 'pdf':
      return <Icon.FileText {...style} />
    case 'video':
      return <Icon.Film {...style} />
    case 'sheet':
      return <Icon.Table {...style} />
    default:
      return <Icon.File {...style} />
  }
}

export const Attachment = ({
  jobEid,
  deliverableEid,
  agreementEid,
  talentEid,
  files,
  expandedJobEid,
  padding = '12px',
}: {
  jobEid?: string
  deliverableEid?: string
  agreementEid?: string
  talentEid?: string
  files?: Types.Job.iFile[]
  expandedJobEid?: string
  padding?: string
}) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { attachJobFileStatus } = useAppSelector(selectJobs)
  const { viewedJob } = useAppSelector(selectJobs)
  const { workspace } = useAppSelector(selectWorkspace)

  const { toggleModal } = useContext(ModalContext)

  const [previewFile, setPreviewFile] = useState<Types.Job.iFile | null>(null)

  const context = useMemo(() => {
    if (jobEid) {
      return 'job'
    } else if (talentEid) {
      return 'talent'
    } else if (agreementEid && deliverableEid) {
      return 'agreement'
    } else if (deliverableEid) {
      return 'deliverable'
    }
  }, [agreementEid, deliverableEid, jobEid, talentEid])

  const refreshJobDetails = useCallback(() => {
    switch (context) {
      case 'job':
        queryClient.invalidateQueries({ queryKey: ['jobDetails', workspace, jobEid], type: 'active' })
        break
      case 'agreement':
      case 'deliverable':
        queryClient.invalidateQueries({ queryKey: ['deliverable'], type: 'active' })
        break
    }
  }, [context, workspace, jobEid, queryClient, viewedJob])

  const detachFile = (fileEid: string) => {
    let action: Promise<PayloadAction<any, string, { requestStatus: string }>>

    switch (context) {
      case 'job':
        if (jobEid) action = dispatch(detachJobAttachment({ eid: jobEid, files: [fileEid] }))
        break
      case 'talent':
        if (talentEid) action = dispatch(detachTalentAttachment({ eid: talentEid, files: [fileEid] }))
        break
      case 'agreement':
        if (agreementEid && deliverableEid)
          action = dispatch(detachAgreementAttachment({ eid: agreementEid, deliverableEid, files: [fileEid], expandedJobEid }))
        break
      case 'deliverable':
        if (deliverableEid) action = dispatch(detachDeliverableAttachment({ eid: deliverableEid, files: [fileEid] }))
        break
    }

    toast.promise(
      // @ts-expect-error
      action.then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          refreshJobDetails()
          if (deliverableEid) {
            queryClient.invalidateQueries({ queryKey: ['deliverables', expandedJobEid], type: 'active' })
          }
          toast.success('Attachment deleted successfully')
        }
      }),
      {
        pending: 'Deleting attachment...',
      }
    )
  }

  const downloadFile = (fileEid: string) => {
    let action: Promise<PayloadAction<any, string, { requestStatus: string }>>

    switch (context) {
      case 'job':
        if (jobEid) action = dispatch(generateJobFileDownloadUrl({ eid: jobEid, files: [fileEid] }))
        break
      case 'talent':
        if (talentEid) action = dispatch(generateTalentFileDownloadUrl({ eid: talentEid, files: [fileEid] }))
        break
      case 'agreement':
        if (agreementEid && deliverableEid) action = dispatch(generateAgreementFileDownloadUrl({ eid: agreementEid, files: [fileEid] }))
        break
      case 'deliverable':
        if (deliverableEid) action = dispatch(generateDeliverableFileDownloadUrl({ eid: deliverableEid, files: [fileEid] }))
        break
    }

    toast.promise(
      // @ts-expect-error
      action.then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setPreviewFile(res.payload[0])
        } else {
          toast.error('Failed to download attachment')
        }
      }),
      {
        pending: 'Loading attachment...',
      }
    )
  }

  return (
    <Stack className={agreementEid ? '' : 'paper-border'} bgcolor="#FFF" padding={padding} rowGap="14px">
      {previewFile && <AttachmentPreview file={previewFile} onClose={() => setPreviewFile(null)} />}
      {!agreementEid && (
        <Stack direction="row" paddingY="10px" columnGap="10px" color={GlobalStyles.SLATE_500}>
          <Icon.File size={20} />
          <Typography fontSize="14px">ATTACHMENTS</Typography>
          <Tooltip text="" />
        </Stack>
      )}
      {!!files?.length ? (
        files?.map(({ eid, original_file_name, full_url, file_format }) => (
          <Stack className="paper-border" direction="row" key={`attachment-${eid}`} columnGap="8px" padding="6px 12px" alignItems="center">
            <Box width="20px">{getFileIcon(file_format)}</Box>
            <Button variant="text" onClick={() => downloadFile(eid)}>
              <Typography flex={1} color={GlobalStyles.SLATE_700} fontSize="14px" sx={{ ...OVERFLOW_TEXT.sx }}>
                {original_file_name}
              </Typography>
            </Button>
            <Button
              variant="text"
              sx={{ marginLeft: 'auto' }}
              onClick={() => {
                trackEvent({ event: TrackingEventEnums.Others.DETACH_FILE, eventProperties: { workspace, context } })
                detachFile(eid)
              }}
              disabled={attachJobFileStatus === 'loading'}>
              Delete
            </Button>
          </Stack>
        ))
      ) : (
        <Stack padding="6px 12px">
          <Typography>No Attachments</Typography>
        </Stack>
      )}
      <PermissionWrapper permissions={viewedJob.permissions} allowedPermissions={[JobPermission.UPLOAD_FILE]}>
        <Stack direction="row" justifyContent="end">
          <Button
            variant="outlined"
            sx={{ height: '40px', paddingX: '12px' }}
            onClick={() => {
              trackEvent({ event: TrackingEventEnums.Others.ATTACH_FILE, eventProperties: { workspace, context } })
              toggleModal(ModalPages.UPLOAD_FILES, {
                modalProps: { uploadFiles: { deliverableEid, jobEid, agreementEid, expandedJobEid, talentEid } },
              })
            }}>
            {agreementEid ? (
              <Stack direction="row" alignItems="center" columnGap="8px">
                <Icon.Lock size={16} />
                <Typography>Attach Private File</Typography>
              </Stack>
            ) : (
              <Stack direction="row" alignItems="center" columnGap="8px">
                <Icon.Paperclip size={16} />
                <Typography>Attach File</Typography>
              </Stack>
            )}
          </Button>
        </Stack>
      </PermissionWrapper>
    </Stack>
  )
}
