import { Orbit } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { selectJobs, setViewedJob, setViewedJobStatus, setViewedJobUsers } from '@redux/reducers/jobsReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useQuery } from '@tanstack/react-query'
import { StatusTypes } from '@utils/types/jobs'
import { useEffect } from 'react'

export default function useJobQuery({
  jobEid,
  fetchDetails = true,
  fetchUsers = true,
}: {
  jobEid: string
  fetchDetails?: boolean
  fetchUsers?: boolean
}) {
  const dispatch = useAppDispatch()
  const { workspace } = useAppSelector(selectWorkspace)
  const { viewedJob } = useAppSelector(selectJobs)
  const IS_JOB_WORKSPACE_VALID = !!jobEid && !!workspace

  const details = useQuery({
    queryKey: ['jobDetails', workspace, jobEid],
    queryFn: async () => {
      const response = await Orbit.Services.jobService.fetchJobById({ jobEid })
      return response.data
    },
    enabled: fetchDetails && IS_JOB_WORKSPACE_VALID,
  })

  const jobUsers = useQuery({
    queryKey: ['jobUsers', workspace, jobEid],
    queryFn: async () => {
      const response = await Orbit.Services.jobService.fetchJobUsers({ jobEid })
      return response.data
    },
    enabled: fetchUsers && IS_JOB_WORKSPACE_VALID,
  })

  useEffect(() => {
    if (fetchDetails && details) {
      details?.data && dispatch(setViewedJob(details.data))
      details.status && dispatch(setViewedJobStatus(details.status as StatusTypes))
    }
  }, [dispatch, fetchDetails, details])

  useEffect(() => {
    fetchUsers && jobUsers?.data && dispatch(setViewedJobUsers(jobUsers.data))
  }, [dispatch, jobUsers, fetchUsers])

  return {
    details: details?.data || viewedJob,
    refetchDetails: details.refetch,
    detailsStatus: details.status,
    isDetailsLoading: details.isLoading,

    users: jobUsers?.data,
    refetchUsers: jobUsers.refetch,
    usersStatus: jobUsers?.status,
    isUsersLoading: jobUsers?.isLoading,
  }
}
