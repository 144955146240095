import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { BasePoint, Descendant, Editor, Range, Transforms } from 'slate'
import { insertMention } from '..'
import { ReactEditor } from 'slate-react'
import { Types } from '@pickstar/orbit'
import { getScrollParent } from '@utils/functions/helperFunctions'
import { trackEvent } from '@utils/tracking/helpers'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { selectDeliverable } from '@redux/reducers/deliverableReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { useMe } from '@utils/query/useMe'
import { isLinkActive } from 'src/components/rich-editor-plugin/link/utils'

const getMatch = (editor: Editor, start: BasePoint, before: BasePoint, matchPattern: RegExp) => {
  const beforeRange = before && Editor.range(editor, before, start)
  const beforeText = beforeRange && Editor.string(editor, beforeRange)

  return {
    range: beforeRange,
    text: beforeText,
    match: beforeText && beforeText.match(matchPattern),
  }
}

const useMentionSelect = (editor: Editor, collection: Types.User.iUser[], type?: 'job' | 'deliverable') => {
  const { viewedJob } = useAppSelector(selectJobs)
  const { viewedDeliverable } = useAppSelector(selectDeliverable)

  const [target, setTarget] = useState<Range | undefined>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [dropdownPosition, setDropdownPosition] = useState({ top: '-99999px', left: '-99999px' })

  const usersToMention = useMemo(() => {
    return collection.filter((c) => c.name.toLowerCase().includes(search.toLowerCase())).slice(0, 10)
  }, [search, collection])

  const { data: currentUser } = useMe()

  const selectMention = useCallback(
    (user: Types.User.iUser) => {
      Transforms.select(editor, target!)
      insertMention(editor, user)
      setTarget(undefined)
      trackEvent({
        event: TrackingEventEnums.Mention.USER_MENTION,
        eventProperties: {
          current_user_id: currentUser?.eid,
          current_user_name: currentUser?.name,
          clicked_user_id: user.eid,
          clicked_user_name: user.name,
          job_id: viewedJob.eid,
          ...(type === 'deliverable' && viewedDeliverable?.eid ? { deliverable_id: viewedDeliverable.eid } : {}),
        },
      })
    },
    [editor, type, currentUser, target, viewedJob, viewedDeliverable]
  )

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (target && usersToMention.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= usersToMention.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? usersToMention.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            selectMention(usersToMention[index])
            break
          case 'Escape':
            event.preventDefault()
            setTarget(undefined)
            break
        }
      }
    },
    [target, usersToMention, index, selectMention]
  )

  const onChange: (value: Descendant[]) => void = useCallback(() => {
    const { selection } = editor
    let timeOut: NodeJS.Timeout

    if (isLinkActive(editor)) {
      return
    }

    setTarget(undefined)

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const before = Editor.before(editor, start)
      const beforeResult = before && getMatch(editor, start, before, /@(\w*)$/)

      const wordBeforeStart = Editor.before(editor, start, { unit: 'word' })
      const wordBeforePoint = wordBeforeStart && Editor.before(editor, wordBeforeStart)
      const wordBeforeResult = wordBeforePoint && getMatch(editor, start, wordBeforePoint, /^@(\w+)$/)

      if (beforeResult?.match || wordBeforeResult?.match) {
        timeOut = setTimeout(() => {
          setTarget(wordBeforeResult?.match ? wordBeforeResult.range : beforeResult?.range)
          setSearch(wordBeforeResult?.match ? wordBeforeResult.match[1] : '')
          setIndex(0)
        }, 0)
      }
    }

    return () => {
      if (timeOut) clearTimeout(timeOut)
    }
  }, [editor])

  useEffect(() => {
    const domRange = target ? ReactEditor.toDOMRange(editor, target) : null
    const scrollElement = domRange ? getScrollParent(domRange.commonAncestorContainer as HTMLElement) : null
    const updatePosition = () => {
      if (target && usersToMention.length > 0) {
        const domRange = ReactEditor.toDOMRange(editor, target)
        const rect = domRange.getBoundingClientRect()

        setDropdownPosition({
          top: `${rect.top + window.pageYOffset - 10}px`, // + 24 if you want to show the dropdown below the mention
          left: `${rect.left + window.pageXOffset}px`,
        })
      }
    }

    updatePosition()

    // fix dropdown position when scrolling, needs to adjust when the scrolling element is changed
    scrollElement?.parentElement?.addEventListener('scroll', updatePosition)

    return () => {
      scrollElement?.parentElement?.removeEventListener('scroll', updatePosition)
    }
  }, [target, usersToMention, editor])

  return {
    // variables
    target,
    dropdownPosition,
    selectedIndex: index,
    filteredSearch: usersToMention,

    // functions
    setTarget,
    selectMention,
    onKeyDown,
    onChange,
    setSelectedIndex: setIndex,
  }
}

export default useMentionSelect
