import { Orbit } from '@pickstar/orbit'
import { useAppSelector } from '@redux/hooks'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useQuery } from '@tanstack/react-query'

export const useUnreadNotificationCount = () => {
  const { activeWorkspace } = useAppSelector(selectWorkspace)

  return useQuery({
    queryKey: ['notification-unread-count', activeWorkspace?.slug],
    queryFn: async () => {
      const res = await Orbit.Services.notificationService.getUserUnreadNotificationsCount()
      // @ts-expect-error
      return res.data.count
    },
    enabled: !!activeWorkspace?.slug,
    refetchInterval: 10000,
  })
}
