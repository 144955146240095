import { ReactEditor, useFocused, useSelected, useSlate } from 'slate-react'
import { ElementProps } from 'src/andromeda/RichEditor/tools'
import { MentionElement } from 'src/andromeda/RichEditor/custom-types'
import { LeafType } from 'remark-slate'
import { Stack, Fade, Popper, Typography } from '@mui/material'
import { CSSProperties, useRef, useState } from 'react'
import { Avatar, GlobalStyles } from '@andromeda'
import { getInitials } from '@utils/functions/helperFunctions'
import { Transforms } from 'slate'

const Mention = ({ attributes, children, users, element }: ElementProps) => {
  const [showDetails, setShowDetails] = useState(false)
  const anchorEl = useRef<HTMLElement | null>(null)
  const selected = useSelected()
  const focused = useFocused()
  const editor = useSlate()

  const style: React.CSSProperties = {
    padding: '2px 6px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    borderRadius: '20px',
    backgroundColor: '#e4e4e4',
    fontSize: '0.9em',
    userSelect: 'none',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  }
  // See if our empty text child has any styling marks applied and apply those
  const firstElement = element.children[0] as LeafType & { underline?: boolean }
  const { fullName, uid } = (element as MentionElement) || {}

  if (firstElement.bold) {
    style.fontWeight = 'bold'
  }

  if (firstElement.italic) {
    style.fontStyle = 'italic'
  }

  if (firstElement.underline) {
    style.textDecoration = 'underline'
  }

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const path = ReactEditor.findPath(editor, element)
    Transforms.select(editor, path)
  }

  return (
    <span
      {...attributes}
      ref={anchorEl}
      className="inline-block relative"
      contentEditable={false}
      data-id={`mention-${fullName?.replace(' ', '-')}`}
      data-user-id={uid}
      style={style}
      onMouseEnter={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}
      onClick={handleClick}>
      @{fullName}
      {children}
      <Popper
        open={showDetails}
        anchorEl={anchorEl?.current}
        popperOptions={{ placement: 'top-start' }}
        transition
        style={{ zIndex: 1251, paddingBottom: '.4rem' }}
        onMouseLeave={() => setShowDetails(false)}>
        {({ TransitionProps }) => {
          const user = users?.find((u) => u.eid === uid)
          const textStyle: CSSProperties = { textAlign: 'center', fontSize: GlobalStyles.FONT_SIZES.TINY }

          return (
            <Fade {...TransitionProps}>
              <Stack
                className="shadow-md"
                direction="row"
                alignItems="center"
                columnGap="8px"
                style={{
                  padding: `${GlobalStyles.PADDING_SIZES.xxs}px ${GlobalStyles.PADDING_SIZES.xs}px`,
                  backgroundColor: '#fff',
                  border: `1px solid ${GlobalStyles.SLATE_50}`,
                  borderRadius: '3px',
                }}>
                {user ? (
                  <>
                    <Avatar
                      size={'xs'}
                      image={user?.profile_image?.full_url}
                      placeHolder={<Typography style={textStyle}>{getInitials(fullName)}</Typography>}
                    />
                    <span className="inline-block">{fullName}</span>
                  </>
                ) : (
                  <Typography style={textStyle}>User does not exist or got removed</Typography>
                )}
              </Stack>
            </Fade>
          )
        }}
      </Popper>
    </span>
  )
}

export default Mention
